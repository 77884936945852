import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { generateErrorMessage } from 'utils';
import {
  CreateVoucherRequestType,
  DeleteVouchersRequestType,
  GetVouchersRequestType,
  UpdateVoucherRequestType,
} from './types';
import api from 'api/middleware';
import config from 'api/config';
import {
  createVoucherFailure,
  createVoucherSuccess,
  deleteVouchersFailure,
  deleteVouchersSuccess,
  getVoucherFailure,
  getVouchersFailure,
  getVouchersRequest,
  getVouchersSuccess,
  getVoucherSuccess,
  updateVoucherFailure,
  updateVoucherSuccess,
} from './reducer';
import { toast } from 'react-toastify';
import { toastSettings } from 'constants/global';
import { history } from 'navigation';
import routes from 'navigation/paths';

export function* getVouchersSaga({
  payload,
}: PayloadAction<GetVouchersRequestType>) {
  try {
    const { data } = yield call(api, config.vouchers, {
      params: payload,
    });
    yield put(getVouchersSuccess(data));
  } catch (e) {
    yield put(getVouchersFailure(generateErrorMessage(e)));
  }
}

export function* createVoucherSaga({
  payload,
}: PayloadAction<CreateVoucherRequestType>) {
  try {
    const { data } = yield call(api.post, config.vouchers, payload);
    yield put(createVoucherSuccess(data));

    history.push(routes.vouchers);
    yield toast.success(
      'Voucher has been created successfully!',
      toastSettings,
    );
  } catch (e) {
    yield put(createVoucherFailure(generateErrorMessage(e)));
  }
}

export function* getVoucherSaga({ payload }: PayloadAction<number>) {
  try {
    const { data } = yield call(api, config.voucher(payload));
    yield put(getVoucherSuccess(data));
  } catch (e) {
    yield put(getVoucherFailure(generateErrorMessage(e)));
  }
}

export function* updateVoucherSaga({
  payload,
}: PayloadAction<{ id: number; dto: UpdateVoucherRequestType }>) {
  try {
    yield call(api.patch, config.voucher(payload.id), payload.dto);
    yield put(updateVoucherSuccess());

    history.push(routes.vouchers);
    yield toast.success(
      'Voucher has been updated successfully!',
      toastSettings,
    );
  } catch (e) {
    yield put(updateVoucherFailure(generateErrorMessage(e)));
  }
}

export function* deleteVouchersSaga({
  payload,
}: PayloadAction<DeleteVouchersRequestType>) {
  try {
    yield call(api.delete, config.vouchers, { data: payload });
    yield put(deleteVouchersSuccess());
    yield put(getVouchersRequest({ limit: 10 }));

    yield toast.success(
      'Vouchers have been deleted successfully!',
      toastSettings,
    );
  } catch (e) {
    yield put(deleteVouchersFailure(generateErrorMessage(e)));
  }
}
