/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateVoucherRequestType,
  DeleteVouchersRequestType,
  GetVouchersRequestType,
  IVoucher,
  IVouchersState,
  UpdateVoucherRequestType,
} from './types';

const initialState: IVouchersState = {
  amount: 0,
  list: [],
  voucher: null,
  error: '',
  loading: false,
};

const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    getVouchersRequest: (
      state,
      { payload }: PayloadAction<GetVouchersRequestType>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getVouchersSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        amount: number;
        list: IVoucher[];
      }>,
    ) => {
      state.loading = false;
      state.amount = payload.amount;
      state.list = payload.list;
    },
    getVouchersFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getVoucherRequest: (state, { payload }: PayloadAction<number>) => {
      state.loading = true;
      state.error = '';
    },
    getVoucherSuccess: (state, { payload }: PayloadAction<IVoucher | null>) => {
      state.loading = false;
      state.voucher = payload;
    },
    getVoucherFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    createVoucherRequest: (
      state,
      { payload }: PayloadAction<CreateVoucherRequestType>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    createVoucherSuccess: (state, { payload }: PayloadAction<IVoucher>) => {
      state.loading = false;
    },
    createVoucherFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    updateVoucherRequest: (
      state,
      { payload }: PayloadAction<{ id: number; dto: UpdateVoucherRequestType }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    updateVoucherSuccess: (state) => {
      state.loading = false;
    },
    updateVoucherFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    deleteVouchersRequest: (
      state,
      { payload }: PayloadAction<DeleteVouchersRequestType>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    deleteVouchersSuccess: (state) => {
      state.loading = false;
    },
    deleteVouchersFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  getVouchersRequest,
  getVouchersSuccess,
  getVouchersFailure,
  createVoucherRequest,
  createVoucherSuccess,
  createVoucherFailure,
  deleteVouchersFailure,
  deleteVouchersSuccess,
  deleteVouchersRequest,
  getVoucherRequest,
  getVoucherSuccess,
  getVoucherFailure,
  updateVoucherRequest,
  updateVoucherSuccess,
  updateVoucherFailure,
} = vouchersSlice.actions;

export const { reducer } = vouchersSlice;
