import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { SelectProps } from './types';

const SelectInfiniteScroll = ({
  loadOptions,
  value,
  onChange,
  ...props
}: SelectProps) => {
  return (
    <AsyncPaginate
      classNamePrefix="react-select"
      debounceTimeout={300}
      loadOptions={loadOptions}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default SelectInfiniteScroll;
