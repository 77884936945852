/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Order,
  getOrdersRequestType,
  getOrderStatusesRequestType,
  OrderStatusesCount,
  OrderWithCustomerAndItem,
  IOrderState,
} from 'redux/orders/types';
import { OrderStatus } from 'constants/global';

const initialState: IOrderState = {
  order: null,
  orders: { amount: 0, list: [] },
  statusesCount: null,
  error: '',
  loading: false,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    getOrderRequest: (
      state,
      { payload }: PayloadAction<{ orderId: string }>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getOrderSuccess: (state, { payload }: PayloadAction<Order | null>) => {
      state.loading = false;
      state.order = payload;
    },
    getOrderFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getOrdersRequest: (
      state,
      { payload }: PayloadAction<getOrdersRequestType>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getOrdersSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        amount: number;
        statuses: Record<keyof typeof OrderStatus, number>;
        list: OrderWithCustomerAndItem[];
      }>,
    ) => {
      state.loading = false;
      state.orders = payload;
    },
    getOrdersFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    exportOrdersToCsvRequest: (
      state,
      { payload }: PayloadAction<getOrdersRequestType>,
    ) => {
      state.error = '';
    },
    exportOrdersToCsvSuccess: (state, { payload }: PayloadAction) => {
      state.error = '';
    },
    exportOrdersToCsvFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
    },
    getOrderStatusesRequest: (
      state,
      { payload }: PayloadAction<getOrderStatusesRequestType>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getOrderStatusesSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{ amount: number; list: OrderStatusesCount[] }>,
    ) => {
      state.loading = false;
      state.statusesCount = payload;
    },
    getOrderStatusesFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersFailure,
  getOrderRequest,
  getOrderSuccess,
  getOrderFailure,
  exportOrdersToCsvRequest,
  exportOrdersToCsvSuccess,
  exportOrdersToCsvFailure,
  getOrderStatusesRequest,
  getOrderStatusesSuccess,
  getOrderStatusesFailure,
} = ordersSlice.actions;

export const { reducer } = ordersSlice;
