export enum SocialMedia {
  INSTAGRAM = 'INSTAGRAM',
}

export type SocialPost = {
  id: string;
  network: SocialMedia;
  imageUrl: string;
  text: string | null;
  items?: { id: number; slug: number }[];
  createdAt?: string;
  updatedAt?: string;
};

export interface ISocialPostsState {
  amount: number;
  list: SocialPost[] | null;
  error: string | null;
  loading: boolean;
}

export interface ISocialMediaState {
  posts: ISocialPostsState;
}

export type AddPostRequest = {
  itemId?: number;
  postId: string;
  network?: SocialMedia;
};

export type ConnectPostToItemRequest = {
  itemId: number;
};

export type DisconnectPostsFromItemRequest = {
  postIds: string[];
  itemId: number;
};

export type GetPostsRequest = {
  limit?: number;
  offset?: number;
  filter?: Record<string, string>;
};
