import {
  exportOrdersToCsvFailure,
  exportOrdersToCsvSuccess,
  getOrderFailure,
  getOrdersFailure,
  getOrdersSuccess,
  getOrderStatusesFailure,
  getOrderStatusesSuccess,
  getOrderSuccess,
} from 'redux/orders/reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { downloadCsv, generateErrorMessage } from 'utils';
import { getOrdersRequestType, getOrderStatusesRequestType } from './types';
import api from 'api/middleware';
import config from 'api/config';

export function* getOrdersStatusesSaga({
  payload,
}: PayloadAction<getOrderStatusesRequestType>) {
  try {
    const { data } = yield call(api, config.ordersStatuses, {
      params: payload,
    });
    yield put(getOrderStatusesSuccess(data));
  } catch (e) {
    yield put(getOrderStatusesFailure(generateErrorMessage(e)));
  }
}

export function* getOrdersSaga({
  payload,
}: PayloadAction<getOrdersRequestType>) {
  try {
    const { data } = yield call(api, config.orders, {
      params: payload,
    });
    yield put(getOrdersSuccess(data));
  } catch (e) {
    yield put(getOrdersFailure(generateErrorMessage(e)));
  }
}

export function* getOrderSaga({ payload }: PayloadAction<{ orderId: string }>) {
  try {
    const { data } = yield call(api, config.order(payload.orderId));
    yield put(getOrderSuccess(data));
  } catch (e) {
    yield put(getOrderFailure(generateErrorMessage(e)));
  }
}

export function* exportOrdersToCsvSaga({
  payload,
}: PayloadAction<getOrdersRequestType>) {
  try {
    const { data } = yield call(api, config.orders, {
      params: { ...payload, exportToCsv: true },
    });
    downloadCsv(data, 'orders');
    yield put(exportOrdersToCsvSuccess());
  } catch (e) {
    yield put(exportOrdersToCsvFailure(generateErrorMessage(e)));
  }
}
