/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddPostRequest,
  DisconnectPostsFromItemRequest,
  GetPostsRequest,
  ISocialMediaState,
  SocialPost,
} from './types';

const initialState: ISocialMediaState = {
  posts: { list: null, amount: 0, error: null, loading: false },
};

const socialMediaSlice = createSlice({
  name: 'socialMedia',
  initialState,
  reducers: {
    addPostRequest: (
      state,
      i: PayloadAction<
        AddPostRequest & { loadAfter?: Partial<GetPostsRequest> }
      >,
    ) => {
      state.posts.loading = true;
      state.posts.error = '';
    },
    addPostSuccess: (state, { payload }: PayloadAction<{ count: number }>) => {
      state.posts.loading = false;
    },
    addPostFailure: (state, { payload }: PayloadAction<string>) => {
      state.posts.error = payload;
      state.posts.loading = false;
    },
    getPostsRequest: (state, i: PayloadAction<GetPostsRequest>) => {
      state.posts.loading = true;
      state.posts.error = '';
    },
    getPostsSuccess: (
      state,
      { payload }: PayloadAction<{ amount: number; list: SocialPost[] }>,
    ) => {
      state.posts.loading = false;
      state.posts.list = payload.list;
      state.posts.amount = payload.amount;
    },
    getPostsFailure: (state, { payload }: PayloadAction<string>) => {
      state.posts.error = payload;
      state.posts.loading = false;
    },
    disconnectPostsFromItemRequest: (
      state,
      i: PayloadAction<
        DisconnectPostsFromItemRequest & {
          loadAfter?: Partial<GetPostsRequest>;
        }
      >,
    ) => {
      state.posts.loading = true;
      state.posts.error = '';
    },
    disconnectPostsFromItemSuccess: (state) => {
      state.posts.loading = false;
    },
    disconnectPostsFromItemFailure: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.posts.error = payload;
      state.posts.loading = false;
    },
  },
});

export const {
  addPostRequest,
  addPostSuccess,
  addPostFailure,
  getPostsRequest,
  getPostsSuccess,
  getPostsFailure,
  disconnectPostsFromItemRequest,
  disconnectPostsFromItemSuccess,
  disconnectPostsFromItemFailure,
} = socialMediaSlice.actions;

export const { reducer } = socialMediaSlice;
