import { IDefinitionProps } from './types';

const Definition = ({ term, definition }: IDefinitionProps) => (
  <p className="mb-1 font-12 text-nowrap overflow-hidden text-overflow-ellipsis">
    <strong className="text-dark min-w-300 d-inline-block">{term}</strong>
    <span className="ms-2 text-body max-w-400">{definition}</span>
  </p>
);

export default Definition;
