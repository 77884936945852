import { OrderStatus } from '../nft/types';
import { FiatCurrency } from '../../constants/global';

export enum StatsType {
  ordersStatusesCount = 'orderStatusesCount',
  itemsTop = 'itemsTop',
  itemsSold = 'itemsSold',
  customersTop = 'customersTop',
  ordersSum = 'ordersSum',
  ordersCount = 'ordersCount',
}

export interface IStatsState {
  data: {
    [StatsType.ordersStatusesCount]: { status: OrderStatus; count: number }[];
    [StatsType.itemsTop]: {
      id: number;
      name: string;
      slug: string;
      designerSlug: string;
      sum: number;
      currency: FiatCurrency;
    }[];
    [StatsType.itemsSold]: {
      id: number;
      name: string;
      slug: string;
      designerSlug: string;
      count: number;
    }[];
    [StatsType.customersTop]: {
      id: number;
      email: string;
      spending: number;
      currency: FiatCurrency;
    }[];
    [StatsType.ordersSum]: {
      date: string;
      sum: number;
      currency: FiatCurrency;
    }[];
    [StatsType.ordersCount]: {
      date: string;
      count: number;
    }[];
  };
  loading: Record<StatsType, boolean>;
  errors: Record<StatsType, string>;
}

export interface StatsRequestFilters {
  dateFrom: Date;
  dateTo: Date;
  limit?: number;
  offset?: number;
}
