import { IAuction } from 'redux/auction/types';
import {
  OrderStatus,
  PaymentProvider,
  IItemMetaType,
  FiatCurrency,
} from 'constants/global';
import { Category } from '../category/types';
import { IBlockChain, IToken } from '../blockchain/types';

export type PaymentProvider =
  typeof PaymentProvider[keyof typeof PaymentProvider];

export type Owner = {
  id: number;
  itemId: number;
  customerId: number;
  orderId: string;
  isOwner: boolean;
  updateAt: Date;
  createdAt: Date;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  meta: Record<string, any>;
};

export declare type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];

export interface ICustomer {
  create2FactoryAddress: string;
  createdAt: string;
  email: string;
  id: number;
  keyAddress: string;
  profileAddress: string;
  salt: string;
  stripeCustomerId: string;
  upByteCodeHash: string;
  usedRecoveryAccount: string;
}
export declare type Order = {
  id: string;
  status: OrderStatus;
  blockchainDeliveryAddress: string;
  createdAt: Date;
  updatedAt: Date;
  fulfilledAt: Date | null;
  canceledAt: Date | null;
  blockchainTransactionId: string | null;
  isPaid: boolean;
  paymentProvider: PaymentProvider;
  paymentId: string;
  ip: string | null;
  rawHeaders: JSON;
  city: string | null;
  country: string | null;
  price: number;
  assetAddress: string;
  failedAt: Date | null;
  failError: string | null;
  itemId: number;
  customerId: number;
  fulfillmentBlockchainId: number | null;
  fulfillmentBlockchain: IBlockChain | null;
  item?: {
    tokens: IToken[];
  };
};

export interface IOrder extends Order {
  customer: ICustomer;
  fulfillmentItemIds: number[];
}

export interface IOwner extends Owner {
  Customer: ICustomer;
}
export interface INFTState {
  nfts: INFTsData | null;
  nft: INFT | null;
  templates: ITemplate[] | null;
  orders: { amount: number; list: IOrder[] } | null;
  owners: { amount: number; list: IOwner[] } | null;
  error: string | null;
  loading: boolean;
  imported: boolean;
}

export enum SaleTypes {
  FIX_PRICE = 'FIX_PRICE',
  AUCTION = 'AUCTION',
}

export interface INFTMeta {
  metaInfo?: string;
  metaImageUrl?: string;
  metaIsPhysical?: boolean;
  metaSizes?: number[];
  additionalFilesUrls?: string[];
  fileUrlsForFreeze?: string[];
  settings?: {
    backgroundColor?: string;
    modelViewer?: {
      exposure?: string;
    };
  };
}

export interface INFT {
  assetAddress: string;
  coverImageUrl: string;
  coverVideoUrl: string;
  createdAt: string;
  description: string;
  designerName: string;
  designerSlug: string;
  price: number;
  currency: FiatCurrency;
  formaItemUuid: null;
  glbUrl: string;
  id: number;
  saleType: SaleTypes;
  imageUrls: string[];
  fake3dImageUrls: string[];
  isSoldOut: false;
  liveAt: string;
  meta: INFTMeta;
  name: string;
  ogImageUrl: string;
  onlyByPromoCode: boolean;
  availableToClaim: boolean;
  highlighted: boolean;
  hideOnShopPage: boolean;
  hideInBackoffice: boolean;
  slug: string;
  story: string;
  usdzUrl: string;
  useCases?: IUseCase[];
  auction: IAuction | null;
  endDate: string | null;
  sort: number;
  limitForCustomer: number;
  dropId: string;
  uuid: string;
  totalSupply?: number;
  availableSupply?: number;
  lockedSupply?: number;
  promoLeft?: number;
  promoCodes?: { id: string }[];
  hash?: string;
  categories?: Category[];
  links: string[];
}

export interface IUseCase {
  ctaLabel: string;
  ctaLink: string;
  icon: string;
  id: number;
  itemId: number;
  image: string;
  productPageText: string;
  templateId: string;
  template: ITemplate;
  text: string;
  title: string;
  sort: number;
  type: IItemMetaType;
}

export interface INFTPayloadStepFirst {
  name: string;
  slug: string;
  assetAddress: string;
  designerName: string;
  designerSlug: string;
  dropId?: number;
  categoryIds?: number[];
  liveAt?: string;
  endDate?: string;
  limitForCustomer: number;
  price: number;
  currency: FiatCurrency;
}

export interface INFTPayload {
  name: string;
  slug: string;
  designerSlug: string;
  assetAddress: string;
  designerName: string;
  coverImageUrl: string;
  coverVideoUrl: string;
  ogImageUrl: string;
  glbUrl: string;
  usdzUrl: string;
  price: number;
  description: string;
  story: string;
  imageUrls: string[];
  fake3dImageUrls: string[];
  meta: INFTMeta;
  liveAt: string;
  saleType: SaleTypes;
  limitForCustomer: number;
  sort: number;
  onlyByPromoCode: boolean;
  availableToClaim: boolean;
  highlighted: boolean;
  hideOnShopPage: boolean;
  categoryIds: number[];
  files: { [key: string]: File[] };
  links: string[];
}

export interface ITemplate {
  ctaLabel: string;
  ctaLink: string;
  icon: string;
  id: string;
  image: string;
  productPageText: string;
  text: string;
  title: string;
  type: IItemMetaType;
}

export interface INFTsData {
  amount: number;
  list: INFT[];
}

export interface IImportNFTData extends INFT {
  templates: ITemplate[];
}

export type CreateUCTRequest = {
  title?: string;
  icon?: string;
  productPageText?: string;
  image?: string;
  text?: string;
  ctaLabel?: string;
  ctaLink?: string;
  type?: string;
  file?: File;
  sort?: number;
};

export type CreateUsecaseRequest = {
  title?: string;
  icon?: string;
  productPageText?: string;
  image?: string;
  text?: string;
  ctaLabel?: string;
  ctaLink?: string;
  type?: IItemMetaType;
  sort?: number;
  file?: File;
};

export type UpdateUCTRequest = {
  title: string;
  icon: string;
  productPageText: string;
  image: string;
  text: string;
  ctaLabel: string;
  ctaLink: string;
  type: IItemMetaType;
  file?: File;
};

export declare type UpdateUsecaseRequest = {
  templateId?: string;
  title?: string;
  icon?: string;
  productPageText?: string;
  image?: string;
  text?: string;
  ctaLabel?: string;
  ctaLink?: string;
  sort?: number;
  type?: IItemMetaType;
  file?: File;
};

export interface GetOrdersApi {
  nftId?: number;
  limit: number;
  offset: number;
  contractId?: string;
  statuses?: string[];
}
