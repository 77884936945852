import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { downloadCsv, generateErrorMessage } from 'utils';
import { getShipmentRequestType, getShipmentsRequestType } from './types';
import api from 'api/middleware';
import config from 'api/config';
import {
  exportShipmentsToCsvFailure,
  exportShipmentsToCsvSuccess,
  getShipmentFailure,
  getShipmentsFailure,
  getShipmentsSuccess,
  getShipmentSuccess,
} from './reducer';

export function* getShipmentsSaga({
  payload,
}: PayloadAction<getShipmentsRequestType>) {
  try {
    const { data } = yield call(api, config.shipments, {
      params: payload,
    });
    yield put(getShipmentsSuccess(data));
  } catch (e) {
    yield put(getShipmentsFailure(generateErrorMessage(e)));
  }
}

export function* getShipmentSaga({
  payload,
}: PayloadAction<getShipmentRequestType>) {
  try {
    const { data } = yield call(api, config.shipment(payload.orderId));
    yield put(getShipmentSuccess(data));
  } catch (e) {
    yield put(getShipmentFailure(generateErrorMessage(e)));
  }
}

export function* exportShipmentsToCsvSaga({
  payload,
}: PayloadAction<getShipmentsRequestType>) {
  try {
    const { data } = yield call(api, config.shipments, {
      params: { ...payload, exportToCsv: true },
    });
    downloadCsv(data, 'shipments');
    yield put(exportShipmentsToCsvSuccess());
  } catch (e) {
    yield put(exportShipmentsToCsvFailure(generateErrorMessage(e)));
  }
}
