import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { generateErrorMessage } from 'utils';
import { getBillingRequestType } from './types';
import api from 'api/middleware';
import config from 'api/config';
import { getBillingFailure, getBillingSuccess } from './reducer';

export function* getBillingSaga({
  payload,
}: PayloadAction<getBillingRequestType>) {
  try {
    const { data } = yield call(api, config.billing(payload.orderId));
    yield put(getBillingSuccess(data));
  } catch (e) {
    yield put(getBillingFailure(generateErrorMessage(e)));
  }
}
