/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BillingInfo, getBillingRequestType, IBillingState } from './types';

const initialState: IBillingState = {
  billingInfo: null,
  error: '',
  loading: false,
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    getBillingRequest: (
      state,
      { payload }: PayloadAction<getBillingRequestType>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getBillingSuccess: (
      state,
      { payload }: PayloadAction<BillingInfo | null>,
    ) => {
      state.billingInfo = payload;
      state.loading = false;
    },
    getBillingFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const { getBillingRequest, getBillingSuccess, getBillingFailure } =
  billingSlice.actions;

export const { reducer } = billingSlice;
