import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { generateErrorMessage } from 'utils';
import api from 'api/middleware';
import config from 'api/config';
import {
  addPostFailure,
  addPostSuccess,
  getPostsFailure,
  getPostsSuccess,
  disconnectPostsFromItemSuccess,
  disconnectPostsFromItemFailure,
} from './reducer';
import {
  AddPostRequest,
  DisconnectPostsFromItemRequest,
  GetPostsRequest,
  SocialMedia,
} from './types';

export function* getPostsSaga({ payload }: PayloadAction<GetPostsRequest>) {
  try {
    const { data } = yield call(api, config.socialPosts, {
      params: {
        limit: payload.limit,
        offset: payload.offset,
        ...payload.filter,
      },
    });

    yield put(getPostsSuccess(data));
  } catch (e) {
    yield put(getPostsFailure(generateErrorMessage(e)));
  }
}

export function* addPostSaga({
  payload: { network = SocialMedia.INSTAGRAM, loadAfter, ...payload },
}: PayloadAction<AddPostRequest & { loadAfter?: Partial<GetPostsRequest> }>) {
  const networkPaths = {
    [SocialMedia.INSTAGRAM]: '/instagram',
  };

  try {
    const { data } = yield call(
      api.post,
      config.socialPosts + networkPaths[network],
      payload,
    );

    if (loadAfter) {
      yield call(
        getPostsSaga.bind(null, {
          type: '',
          payload: {
            limit: loadAfter.limit,
            offset: loadAfter.offset,
            filter: { itemId: String(payload.itemId) },
          },
        }),
      );
    }

    yield put(addPostSuccess(data));
  } catch (e) {
    yield put(addPostFailure(generateErrorMessage(e)));
  }
}

export function* disconnectPostsFromItemSaga({
  payload: { loadAfter, ...payload },
}: PayloadAction<
  DisconnectPostsFromItemRequest & { loadAfter?: Partial<GetPostsRequest> }
>) {
  try {
    yield call(api.post, config.socialPosts + `/disconnect`, payload);

    if (loadAfter) {
      yield call(
        getPostsSaga.bind(null, {
          type: '',
          payload: {
            limit: loadAfter.limit,
            offset: loadAfter.offset,
            filter: { itemId: String(payload.itemId) },
          },
        }),
      );
    }

    yield put(disconnectPostsFromItemSuccess());
  } catch (e) {
    yield put(disconnectPostsFromItemFailure(generateErrorMessage(e)));
  }
}
