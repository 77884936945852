/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getShipmentRequestType,
  getShipmentsRequestType,
  IShipmentsState,
  ShipmentInfo,
} from './types';

const initialState: IShipmentsState = {
  amount: 0,
  list: [],
  shipmentInfo: null,
  error: '',
  loading: false,
};

const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    getShipmentsRequest: (
      state,
      { payload }: PayloadAction<getShipmentsRequestType>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getShipmentsSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        amount: number;
        list: ShipmentInfo[];
      }>,
    ) => {
      state.loading = false;
      state.amount = payload.amount;
      state.list = payload.list;
    },
    getShipmentsFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    getShipmentRequest: (
      state,
      { payload }: PayloadAction<getShipmentRequestType>,
    ) => {
      state.loading = true;
      state.error = '';
    },
    getShipmentSuccess: (
      state,
      { payload }: PayloadAction<ShipmentInfo | null>,
    ) => {
      state.loading = false;
      state.shipmentInfo = payload;
    },
    getShipmentFailure: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    exportShipmentsToCsvRequest: (
      state,
      { payload }: PayloadAction<getShipmentsRequestType>,
    ) => {
      state.error = '';
    },
    exportShipmentsToCsvSuccess: (state, { payload }: PayloadAction) => {
      state.error = '';
    },
    exportShipmentsToCsvFailure: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.error = payload;
    },
  },
});

export const {
  getShipmentsRequest,
  getShipmentsSuccess,
  getShipmentsFailure,
  getShipmentRequest,
  getShipmentSuccess,
  getShipmentFailure,
  exportShipmentsToCsvRequest,
  exportShipmentsToCsvSuccess,
  exportShipmentsToCsvFailure,
} = shipmentsSlice.actions;

export const { reducer } = shipmentsSlice;
